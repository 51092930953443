:root {
  --theme-color: #2a8299;
  --theme-color1: #341b13;
  --theme-color2: #f8b442;

  --theme: #074b9a;
  --theme1: #ffda3b;
  --theme3: #0056a2;
  --theme4: #eff3f9;
  --theme2: #001a4d;
  --text: #49556f;
  --link: #034c9e;

  --body-bg: #ffffff;
  --font-color: #49556f;
  --font-white: #ffffff;
  --heading-color: #02335b;
  --border-color: #c9c9c9;
  --font-white: #ffffff;
  --link-color: #034c9e;

  --theme-color-hover: #065b72;
}

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  color: var(--font-color);
}

h1,
h2,
h3 {
  color: var(--heading-color);
  margin-bottom: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
}

h4,
h5 {
  color: var(--heading-color);
  margin-bottom: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
}

p {
  margin-bottom: 0;
}

a,
a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.theme-btn {
  border-radius: 16px;
  height: 51px;
  text-align: center;
  border: none;
  max-width: max-content;
  min-width: 100px;
  width: auto;
  padding: 10px 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-weight: 700;
}

.btn-main {
  color: var(--font-white);
  background: var(--theme-color);
  transition: all 0.5s ease-out;
  white-space: nowrap;
  min-width: 100px;
}

.btn-main:hover {
  color: var(--font-white);
  background: var(--theme-color-hover);
  transition: all 0.5s ease-out;
}

.btn-secondary {
  color: var(--font-white);
  background-color: #474747;
  transition: all 0.5s ease-out;
}

.btn-secondary:hover {
  color: var(--font-white);
  background-color: #5c636a;
  transition: all 0.5s ease-out;
}

.btn-border {
  color: var(--heading-color);
  background-color: transparent;
  transition: all 0.5s ease-out;
  border: 1px solid var(--heading-color);
}

.btn-border:hover {
  color: var(--font-white);
  background-color: var(--heading-color);
  transition: all 0.5s ease-out;
}

.main-container {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0rem 0rem 0rem 0rem;
}

.page-header-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.top-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 0px;
  min-height: 45px;
  background-color: var(--body-bg);
}

.pageBrand-name {
  font-size: 20px;
  font-family: "Lato-Black", sans-serif;
  color: var(--heading-color);
}

.pageBrand-name span {
  color: var(--theme-color2);
}

.banner-header {
  width: 100%;
  height: 350px;
  position: relative;
  display: inline-block;
  background-image: url("./images/new-banner.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.banner-header::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  z-index: 9;
}

.main-form-container {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: -4rem;
  z-index: 11;
  overflow: hidden;
}

.form-content-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  /* border: 1px solid var(--border-color); */
  height: 100%;
  box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: var(--body-bg);
}

/* .form-info-banner-wrapper {
  width: 100%;
  height: 300px;
  position: relative;
  display: inline-block;
  background-image: url('../images/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px 12px 0px 0px;
}

.form-info-banner-wrapper::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 9;
  border-radius: 12px 12px 0px 0px;
} */

.form-info-banner-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4rem 0rem;
  background-color: var(--font-white);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.form-info-banner {
  position: relative;
  width: 100%;
  z-index: 10;
  height: 100%;
  padding: 0rem 3rem;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.packageImg {
  margin: auto;
  width: 85px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.info-banner-heading {
  text-align: center;
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  font-size: 34px;
}

.info-banner-description {
  text-align: center;
  margin-bottom: 10px;
  font-size: 18px;
}

.info-banner-small-description {
  text-align: center;
}

.step-pickup-wrapper {
  position: relative;
  display: inline-block;
  padding: 2.5rem 0rem;
  margin: 0px 16px;
}

.step-count {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: var(--theme-color);
  margin: auto;
}

.step-count h2 {
  font-size: 20px;
  color: var(--font-white);
}

.step-info {
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 1.5rem 0rem;
}

.step-info h3 {
  text-align: center;
  color: var(--heading-color);
  width: 100%;
  margin-bottom: 6px;
}

.step-info p {
  text-align: center;
}

.form-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 2.5rem;
  /* border: 1px solid var(--border-color); */
  border-radius: 12px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
}

.form-input-group {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

.form-input-group .error-msg {
  color: red;
  font-size: 12px;
}

.form-label {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--heading-color);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
}

.form-input {
  width: 100%;
  padding: 5px 0px;
  min-height: 50px;
  border: none;
  border-bottom: 1px solid var(--border-color);
}

.form-input:focus,
.form-input:hover,
.form-input:active {
  box-shadow: none;
  outline: none;
}

.form-checkbox-info h3 {
  font-size: 15px;
  margin-bottom: 4px;
}

.form-checkbox-info p {
  font-size: 12px;
  line-height: 16px;
}

.form-checkbox-list {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.form-checkbox-item {
  margin-bottom: 15px;
  position: relative;
}

.form-checkbox-item:last-child {
  margin-bottom: 0px;
}

.form-checkbox-item input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--font-white);
  margin: 0;
  font: inherit;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border: 1px solid var(--border-color);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.form-checkbox-item input[type="checkbox"]::before {
  content: "";
  width: 11px;
  height: 11px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: var(--theme-color);
}

.form-checkbox-item input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.form-checkbox-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.form-checkbox-info {
  margin-right: 20px;
}

.unit-selection {
  display: flex;
  align-items: center;
}

.unit-increase-decrease {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.unit-btn {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-color);
  border: none;
  box-shadow: none;
  font-size: 8px;
  color: var(--font-white);
}

.unit-count {
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: var(--heading-color);
  margin: 0px 10px;
}

.info-with-input {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}

.info-with-input p {
  color: var(--font-color);
  font-size: 12px;
  line-height: 16px;
  position: relative;
}

.info-with-input span {
  color: var(--font-color);
  font-size: 12px;
  line-height: 16px;
  position: relative;
}

.info-with-input .error-msg {
  color: red;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
}

.box-dimension {
  min-height: 35px;
}

.switch-btn {
  background-color: var(--theme-color);
  border: none;
  font-size: 12px;
  color: var(--font-white);
  padding: 3px 10px;
  border-radius: 3px;
  margin-top: 5px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-more-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-more-wrapper .btn-main i {
  margin-right: 10px;
}

.remove-btn {
  width: fit-content;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0000;
  float: right;
  margin-top: 1rem;
  border: none;
  margin-left: auto;
  background-color: transparent;
}

.remove-btn i {
  margin-right: 5px;
}

.shipping-content .form-wrapper {
  margin-bottom: 30px;
  padding-bottom: 4rem;
}

.addmore-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--link-color);
  font-family: "Plus Jakarta Sans", sans-serif;
}

.addmore-btn i {
  margin-right: 5px;
}

.get-btns-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5rem 0rem 5rem 0rem;
  flex-direction: column;
}

.gtnBlock {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.get-btns-wrapper .btn-main i {
  margin-left: 10px;
}

.quote-result-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 2rem;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: rgb(82 97 228 / 10%);
}

.quote-result-heading {
  font-size: 20px;
  margin-bottom: 2rem;
}

.quote-result-item {
  display: flex;
  width: 100%;
  align-items: start;
}

.carrier-name {
  flex: 40%;
}

.carrier-name h3 {
  font-size: 18px;
}

.quote-info {
  flex: 60%;
  display: inline-block;
  position: relative;
}

.quote-info-list {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.quote-info-list p {
  flex: 50%;
}

.quote-info-list h4 {
  font-size: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  flex: 50%;
  text-align: right;
  margin-bottom: 10px;
}

.quote-info-list div {
  flex: 50%;
}

.quote-info-list div h4 {
  text-align: right;
  margin-bottom: 5px;
}

.quote-info-list h3 {
  flex: 50%;
  text-align: right;
  font-size: 24px;
}

.quote-info-list div span {
  font-size: 12px;
  color: var(--font-color);
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-right: 15px;
}

.quote-info-list.price {
  margin-top: 20px;
}

.quote-result-list .quote-result-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
  width: 100%;
}

.quote-result-list .quote-result-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.form-label i {
  margin-left: 5px;
  color: var(--theme-color);
  cursor: pointer;
}

.form-checkbox-info h3 i {
  margin-left: 5px;
  color: var(--theme-color);
  cursor: pointer;
}

.info-with-input span i {
  margin-left: 5px;
  color: var(--theme-color);
  cursor: pointer;
}

.info-box {
  min-width: 16rem;
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  position: absolute;
  display: inline-block;
  background-color: var(--font-white);
  z-index: 11;
  top: 4%;
  transform: translate(-25%, -50%);
  opacity: 0;
  visibility: hidden;
}

.info-box h3 {
  font-size: 12px;
  margin-bottom: 6px;
}

.info-box p {
  font-size: 12px;
  margin-bottom: 5px;
  color: var(--font-color);
  font-family: "Plus Jakarta Sans", sans-serif;
  line-height: 16px;
  font-weight: 400;
}

.info-box p:last-child {
  margin-bottom: 0px;
}

.info-box::before {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--theme-color);
  position: absolute;
  content: "";
  display: inline-block;
  top: 50%;
  left: -10px;
}

.form-label .picklocTypeHover:hover ~ .picklocTypeDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.truckHover:hover ~ .truckDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.insideHover:hover ~ .insideDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.deliveryTypeHover:hover ~ .deliveryTypeDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.deliveryAppiontHover:hover ~ .deliveryAppiontDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.deliveryTruckHover:hover ~ .deliveryTruckDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.deliveryInsideHover:hover ~ .deliveryInsideDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.handUnitHover:hover ~ .handUnitDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.palletDescripHover:hover ~ .palletDescripDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.nmfcHover:hover ~ .nmfcDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.stackHover:hover ~ .stackDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.tempHover:hover ~ .tempDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.dangerHover:hover ~ .dangerDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.dimensionsHover:hover ~ .dimensionsDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -50%);
}

.addmorehover:hover ~ .addmoreDisplay {
  opacity: 1;
  visibility: visible;
  transform: translate(10%, -105%);
  margin-bottom: 35px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.add-more-wrapper.dropdown .dropdown-menu {
  min-width: 16rem;
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 12px;
  background-color: var(--font-white);
  top: -150px;
}

.add-more-wrapper.dropdown .dropdown-menu::before {
  border-top: 10px solid var(--theme-color);
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  top: auto;
  bottom: -20px;
  left: 50%;
  width: 0px;
  height: 0px;
  position: absolute;
  content: "";
  display: inline-block;
}

/* datepicker */
.ui-datepicker-header {
  background-color: var(--font-white);
  color: var(--heading-color);
  text-align: center;
  font-family: "Lato-Black", sans-serif;
  padding: 5px;
  height: 55px;
  border-radius: 8px 8px 0px 0px;
  font-size: 20px;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: none;
}

.ui-datepicker-prev:after {
  content: "<";
  font-size: 2rem;
  float: left;
  margin-left: 10px;
  cursor: pointer;
}

.ui-datepicker-title {
  margin-top: 3px;
}

.ui-datepicker-next:after {
  content: ">";
  float: right;
  font-size: 2rem;
  margin-right: 10px;
  cursor: pointer;
}

.ui-datepicker-calendar th {
  padding: 5px;
  color: var(--heading-color);
  max-width: 38px;
  min-width: 38px;
  width: 48px;
  min-height: 38px;
  max-height: 38px;
  height: 38px;
  font-weight: normal;
}

.ui-datepicker-calendar {
  text-align: center;
  margin: 0 auto;
  padding: 8px;
  width: 100%;
}

.ui-datepicker-calendar td {
  position: relative;
  border: 1px solid #e9e9e9;
}

.ui-datepicker-calendar .ui-state-default {
  text-decoration: none;
  color: var(--font-color);
  padding: 4px 0px;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.ui-datepicker-calendar .ui-state-default:hover {
  background-color: rgb(232 232 232);
}

.ui-datepicker-calendar .ui-state-active {
  color: var(--font-white);
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: var(--theme-color);
}

.ui-datepicker-calendar .ui-state-active:hover {
  background-color: var(--theme-color);
}

/* #calendarNext .ui-datepicker-prev{
  display: none;
}

#calendar .ui-datepicker-next{
  display: none;
} */

/* #calendar .ui-datepicker-header{
  border-top-right-radius: 0px;
}

#calendarNext .ui-datepicker-header{
  border-top-left-radius: 0px;
} */

td.ui-datepicker-week-end {
  background-color: rgb(232 232 232);
}

.ui-datepicker-next:after {
  content: "\f105";
  font-family: "Fontawesome", sans-serif;
  margin-right: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--font-white);
  border-radius: 50%;
  color: var(--font-color);
  font-size: 16px;
  border: 1px solid var(--border-color);
}

.ui-datepicker-prev:after {
  content: "\f104";
  font-family: "Fontawesome", sans-serif;
  margin-left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--font-white);
  border-radius: 50%;
  color: var(--font-color);
  font-size: 16px;
  border: 1px solid var(--border-color);
}

.ui-state-disabled {
  opacity: 0.5;
}

/* page v1 */
.page-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.page-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  height: 80px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

.page-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-tabs .nav-pills .nav-link {
  display: flex;
  padding: 0rem 1rem;
  color: inherit;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.page-tabs .nav-pills .nav-link.active {
  background-color: var(--font-white);
  color: var(--theme-color);
}

.navstep-count {
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-color: var(--font-white);
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  margin-right: 10px;
}

.page-tabs .nav-pills .nav-link.active .navstep-count {
  border-color: var(--theme-color);
}

.page-brand h2 {
  font-size: 24px;
}

.page-info-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  height: calc(100vh - 161px);
  background-color: var(--theme-color);
  padding: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

.onepage {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  padding: 2rem;
  border: 1px solid var(--border-color);
  border-radius: 16px;
}

.page-info-container {
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 3rem 0rem 2rem 0rem;
}

.onepage .step-info {
  margin-top: 0px;
}

.onepage .step-info h3 {
  text-align: left;
}

.onepage .step-info p {
  text-align: left;
}

.action-btns-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.action-btns-wrapper .theme-btn {
  margin-left: 15px;
}

.onepage .add-more-wrapper {
  margin-bottom: 20px;
  justify-content: flex-end;
}

.shipment-info-list {
  width: 100%;
  position: relative;
  display: inline-block;
}

.shipment-info-item {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.shipment-info-item:last-child {
  margin-bottom: 0px;
  padding-bottom: 0px;
  border-bottom: none;
}

.quotes-heading {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-top: 2.5rem;
}

.quotes-heading h3 {
  font-size: 20px;
  margin-bottom: 0;
}

.quote-list {
  width: 100%;
  position: relative;
  display: inline-block;
}

.quote-item {
  width: 100%;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 30px 20px;
  margin-bottom: 24px;
}

.quote-item:last-child {
  margin-bottom: 0px;
}

.quote-item-logo {
  width: 150px;
  min-width: 150px;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.quote-item-logo img {
  width: 80px;
}

.quote-item-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quote-item-heading {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

.quote-item-result {
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 18px;
}

.quote-item-transit .quote-item-result {
  display: flex;
  align-items: center;
}

.quote-item-transit .quote-item-result .plus {
  font-size: 10px;
  color: var(--font-color);
  margin: 0px 5px;
}

.quote-item-transit .quote-item-result .days {
  font-size: 10px;
  color: var(--font-color);
}

.quote-item-price h1 {
  font-size: 28px;
  display: flex;
  align-items: flex-start;
}

.quote-item-price h1 .price-dollar {
  font-size: 18px;
  margin-top: 3px;
  margin-right: 2px;
}

.quote-item-price h1 .price-currency {
  font-size: 20px;
  color: var(--font-color);
  margin-top: 6px;
  margin-left: 3px;
}

.quote-main-content {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quote-drop-wrapper {
  min-width: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.quote-drop-wrapper button {
  padding: 0;
  border: none;
  background: transparent;
}

.quote-drop-wrapper button[aria-expanded="false"]::after {
  content: "\f107";
  font-size: 35px;
  font-family: "Fontawesome", sans-serif;
  position: relative;
  display: inline-block;
  color: var(--font-color);
  width: auto;
  height: auto;
}

.quote-drop-wrapper button[aria-expanded="true"]::after {
  content: "\f106";
  font-size: 35px;
  font-family: "Fontawesome", sans-serif;
  position: relative;
  display: inline-block;
  color: var(--font-color);
  width: auto;
  height: auto;
}

.quote-collapse-content {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 3rem 0;
}

.qcc-head {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qcc-head-left h2 {
  font-size: 20px;
  margin-bottom: 5px;
}

.qcc-head-right {
  text-align: right;
}

.qcc-head-right h2 {
  font-size: 42px;
  display: flex;
}

.qcc-head-right h2 .qcc-dollar {
  font-size: 24px;
  margin-top: 8px;
  display: flex;
  align-items: baseline;
}

.qcc-head-right h2 .qcc-currency {
  font-size: 24px;
  color: var(--font-color);
  margin-left: 5px;
  display: flex;
  align-items: flex-end;
}

.qcc-body {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 3rem;
}

.qcc-body-list {
  flex: 50%;
  position: relative;
  display: inline-block;
}

.qcc-body-list-heading {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 25px;
}

.qcc-body-list-heading h3 {
  font-size: 18px;
  color: var(--font-color);
}

.qcc-detail-item {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
}

.qcc-detail-item:last-child {
  margin-bottom: 0px;
}

.qcc-detail-small-heading {
  font-size: 14px;
  margin-bottom: 2px;
}

.qcc-detail-result-heading {
  font-size: 16px;
  margin-bottom: 2px;
}

.qcc-detail-item p {
  font-size: 14px;
}

.qcc-detail-item ul {
  list-style: disc;
  padding-left: 18px;
  margin-top: 5px;
}

.qcc-body-list:before {
  width: 100%;
  height: 5px;
  background-color: var(--border-color);
  position: relative;
  content: "";
  display: inline-block;
  margin-bottom: 1rem;
}

.qcc-body-list:last-child::before {
  background-color: transparent;
}

.qcc-body-list::after {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -8px;
  display: inline-block;
  background-color: var(--border-color);
  border-radius: 50%;
  content: "";
  left: 0;
}

.qcc-details {
  width: 100%;
  position: relative;
  padding-right: 20px;
  display: inline-block;
}

.quote-item-content div {
  padding-right: 20px;
}

.quote-item-content div:last-child {
  padding-right: 0px;
}

.pageBrand-logo {
  position: relative;
  width: 172px;
}

.error-message {
  font-size: 12px;
}

.features-container {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 60px 0px;
  margin-top: 5rem;
  background-color: #ffffff;
}

.feature-card-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 20px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 12px;
  padding-top: 2.5rem;
}

.feature-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  top: -20px;
}

.feature-icon.one {
  background-color: #00a6f3;
}

.feature-icon.two {
  background-color: #82c552;
}

.feature-icon.three {
  background-color: #ff9a27;
}

.feature-icon.four {
  background-color: #f3205f;
}

.feature-icon.five {
  background-color: #424eb4;
}

.feature-icon.six {
  background-color: #00bbd4;
}

.feature-icon.seven {
  background-color: #c9de45;
}

.feature-icon.eight {
  background-color: #ff5a17;
}

.feature-card-wrapper h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.col-item-mb {
  margin-bottom: 3rem;
}

.important-info-container {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 60px 0px;
  background-color: var(--heading-color);
}

.section-heading-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

.section-heading {
  text-align: center;
  width: 100%;
  margin-bottom: 0px;
}

.info-content-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.info-content-count {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: var(--theme1);
  color: var(--font-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-content-count h3 {
  font-size: 18px;
  color: var(--font-white);
  margin: 0;
}

.info-contents {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-left: 20px;
}

.info-contents h3 {
  font-size: 24px;
  color: var(--font-white);
  margin-bottom: 20px;
}

.info-contents p {
  color: var(--font-white);
}

.transit-time-container {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 60px 0px;
}

.transittime-table {
  width: 100%;
  position: relative;
  display: inline-block;
}

.transittime-table table {
  width: 100%;
}

.transittime-table table thead tr th {
  padding: 10px;
  font-weight: normal;
  font-family: "Plus Jakarta Sans", sans-serif;
  color: var(--heading-color);
  border: 1px solid var(--border-color);
  border-collapse: collapse;
}

.transittime-table table tbody tr td {
  padding: 10px;
  border: 1px solid var(--border-color);
  border-collapse: collapse;
}

.transittime-table table tbody tr:nth-child(odd) td {
  background-color: #fff7f7;
}

.transittime-table table tbody tr:nth-child(even) td {
  background-color: #ffffff;
}

footer {
  width: 100%;
  position: relative;
  display: inline-block;
}

.footer-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 20px 0px;
  margin-top: 30px;
  text-align: center;
  border-top: 1px solid var(--border-color);
}

.datepicker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.react-datepicker__current-month {
  padding: 8px 0px;
  color: var(--heading-color) !important;
}

.react-datepicker__day--selected {
  background-color: var(--theme-color) !important;
}

.react-datepicker__navigation {
  top: 8px !important;
  height: 30px !important;
  width: 30px !important;
  background-color: var(--font-white) !important;
  border-radius: 50% !important;
  border: 1px solid var(--border-color) !important;
}

.react-datepicker__navigation--next {
  right: 10px !important;
}

.react-datepicker__navigation--previous {
  left: 10px !important;
}

.react-datepicker__navigation-icon::before {
  top: 9px !important;
}

.react-datepicker__navigation-icon--next::before {
  left: -4px !important;
}

.react-datepicker__navigation-icon--previous::before {
  right: -4px !important;
}

.react-datepicker__header {
  background-color: var(--font-white) !important;
}

.react-datepicker {
  border: 1px solid var(--border-color) !important;
}

.react-datepicker__header {
  border-bottom: 1px solid var(--border-color) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--theme-color) !important;
  color: var(--font-white) !important;
}

.react-datepicker__day--weekend {
  background-color: rgb(232 232 232) !important;
}

.react-datepicker__day--selected.react-datepicker__day--weekend {
  background-color: var(--theme-color) !important;
  color: var(--font-white) !important;
}

.quote-item-content.hide {
  display: none;
}

.emailInfoModal .modal-dialog {
  height: calc(100vh - 56px);
  display: flex;
  align-items: center;
}

.emailInfoModal .modal-title {
  font-size: 18px;
  color: var(--heading-color);
}

.emailInfoModal .modal-header {
  padding: 12px 1rem;
}

.emailInfoModal-content-wrapper p a {
  text-decoration: underline;
  font-weight: 400;
  color: var(--heading-color);
}

@media (min-width: 576px) {
  .emailInfoModal .modal-dialog {
    max-width: 700px;
  }
}

.postalCode-city-inputs {
  width: 100%;
  position: relative;
  display: flex;
}

.form-input-half {
  width: 50%;
  flex: 50%;
  padding: 5px 0px;
  min-height: 50px;
  border: none;
  border-bottom: 1px solid var(--border-color);
}

.form-input-half:active,
.form-input-half:focus {
  outline: none;
  box-shadow: none;
}

.error-msg {
  font-size: 12px;
  color: #ff0000;
  line-height: 18px;
  display: inline-block;
}

.topheader-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topheader-contact a {
  font-weight: 600;
  font-size: 13px;
  margin-left: 20px;
  position: relative;
  display: inline-block;
  width: auto;
  color: var(--link-color);
}

.topheader-contact a i {
  margin-right: 10px;
  color: var(--theme-color);
}

.start-again-btn {
  color: var(--link-color);
  font-weight: 600;
  font-size: 14px;
}

.start-again-btn:hover {
  color: var(--link-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: #ffffff !important;
  color: #000000 !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000000 !important;
}

.startAgainModal .modal-dialog {
  height: calc(100vh - 56px);
  display: flex;
  align-items: center;
}

.startAgainModal-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
}

.startAgainModal-content-wrapper h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

.startAgainModal-btns {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.startAgainModal-btns .theme-btn {
  margin: 0px 10px;
}

.pageloader {
  position: absolute;
  width: 100%;
  height: calc(100vh);
  z-index: 1010;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loaderImg {
  width: 80px;
}

.buttonSpinnerBlock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.buttonSpinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backtoBook {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 20px 0px;
}

.backtoBook a {
  color: var(--link-color);
  font-size: 14px;
  font-weight: 600;
}

.backtoBook a i {
  margin-right: 10px;
}

.inputMap {
  width: 100%;
  position: relative;
  display: inline-block;
  /* height: 160px; */
  /* border: 1px solid var(--border-color); */
  margin-top: 10px;
}

.step-contact-details-wrapper {
  position: relative;
  display: inline-block;
  padding: 2.5rem 0rem;
  margin: 0px 16px;
}

.start-again-btn:active {
  border: none;
  box-shadow: none;
}

.pageBrand-img {
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.shipment-ship-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shipment-ship-heading {
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-weight: 600 !important;
  color: var(--heading-color) !important;
  margin-bottom: 1rem !important;
  font-size: 15px !important;
}

.shipment-ship-link {
  background-color: transparent;
  border: none;
  color: var(--link-color);
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Home v1 styles start */
.theme-btn {
  border-radius: 16px;
}

.call-link {
  margin-right: 25px;
  font-size: 16px;
  color: #21414d;
  display: flex;
  align-items: center;
}

.call-link i {
  margin-right: 10px;
  font-size: 24px;
}

.navbar-scrolled {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

footer {
  background-color: #0f272e;
  position: relative;
  display: inline-block;
  padding-top: 4rem;
}

.footer-about-text {
  color: var(--font-white);
  margin-top: 2rem;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 30px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

.footer-links {
  width: 100%;
  position: relative;
  display: inline-block;
}

.footer-heading {
  width: 100%;
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: var(--font-white);
  margin-bottom: 20px;
  line-height: 25px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.footer-link-lists {
  width: 100%;
  position: relative;
  display: inline-block;
}

.footer-link-lists li {
  margin-bottom: 25px;
}

.footer-link-lists li:last-child {
  margin-bottom: 0px;
}

.footer-link-lists li a {
  width: 100%;
  position: relative;
  display: inline-block;
  color: var(--font-white);
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.footer-contact-item {
  width: 100%;
  position: relative;
  display: inline-block;
  color: var(--font-white);
  margin-bottom: 25px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.footer-contact-item i {
  margin-right: 15px;
  font-size: 28px;
}

.footer-social {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 0.5rem;
}

.footer-social ul li {
  display: inline-block;
  margin-right: 15px;
}

.footer-social ul li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--font-white);
  color: var(--font-white);
}

.footer-social ul li a i {
  font-size: 20px;
}

.footer-bottom-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  border-top: 1px solid #3b4e54;
  margin-top: 4rem;
}

.footer-bottom-wrapper p {
  color: var(--font-white);
  font-size: 16px;
  line-height: 38px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  /* letter-spacing: 10px; */
}

.home-page-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.main-banner-container {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 90vh;
  background-color: var(--theme-color);
  background-image: url("./images/banner-img.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-banner-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-blue {
  background-color: #00a6ef;
  color: var(--font-white);
  min-height: 77px;
  min-width: 200px;
  transition: ease-in-out 0.3s;
  font-size: 24px;
  font-family: 700;
  font-family: "Inter", sans-serif;
  animation: blurr 2s;
  animation-iteration-count: infinite;
  outline: none;
  cursor: pointer;
  border: none;
}

@keyframes blurr {
  0%,
  100% {
    box-shadow: 0 0 10px hsl(198.33deg 100% 46.86%);
  }

  50% {
    box-shadow: 0 0 36px hsl(198.33deg 100% 46.86%);
  }
}

@keyframes fade {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.btn-blue:hover {
  background-color: #0b74a3;
  transition: ease-in-out 0.3s;
  color: var(--font-white);
}

.banner-heading {
  text-transform: uppercase;
  color: var(--font-white);
  font-size: 52px;
  margin-bottom: 25px;
  line-height: 67px;
  font-weight: 700;
}

.banner-small-heading {
  color: var(--font-white);
  font-size: 28px;
  margin-bottom: 25px;
  text-transform: uppercase;
  line-height: 46px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.banner-layer-container {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: -3rem;
}

.banner-layer-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px;
  border-radius: 16px;
  background-color: var(--font-white);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.banner-layer-wrapper h4 {
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  color: #202837;
  font-weight: 600;
}

.banner-layer-wrapper img {
  margin-bottom: 25px;
}

.section-features {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 3rem 0rem;
}

.section-heading-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 3rem;
}

.section-heading {
  font-size: 44px;
  text-align: center;
  margin-bottom: 25px;
  line-height: 56px;
  font-weight: 700;
}

.section-description {
  text-align: center;
  font-size: 20px;
  line-height: 36px;
  color: #49556f;
}

.features-card-wrapper {
  background-color: #f4f5f9;
  padding: 30px 25px;
  position: relative;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 16px;
  width: 100%;
}

.features-card-wrapper img {
  margin: auto;
  text-align: center;
  display: flex;
}

.features-card-wrapper h3 {
  padding: 20px 0px 15px 0px;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #19333d;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.features-card-wrapper p {
  text-align: center;
  color: #546e7a;
  font-size: 16px;
  line-height: 24px;
}

.section-information {
  background-color: #202737;
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4rem 0rem;
}

.information-card-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 2.5rem;
}

.information-card-item {
  background-color: #1c5261;
  height: 100%;
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4rem 20px 20px 20px;
  border-radius: 16px;
  margin-bottom: 7rem;
}

.information-card-item:last-child {
  margin-bottom: 0rem;
}

.icard-content h3 {
  font-size: 20px;
  color: var(--font-white);
  margin-bottom: 15px;
  line-height: 25px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

.icard-content p {
  color: #9cbfcb;
  font-size: 15px;
  line-height: 22px;
}

.icard-count {
  width: 80px;
  height: 80px;
  border-radius: 16px;
  border: 6px solid #063642;
  background-color: #00a6ef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icard-count h4 {
  font-size: 38px;
  color: var(--font-white);
  line-height: 46px;
}

.information-card-wrapper.left .icard-count {
  position: absolute;
  top: -40px;
  right: 20px;
}

.information-card-wrapper.left .icard-content h3 {
  text-align: right;
}

.information-card-wrapper.left .icard-content p {
  text-align: right;
}

.information-card-wrapper.right .icard-count {
  position: absolute;
  top: -40px;
  left: 20px;
}

.information-card-image {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-transitime {
  background-color: var(--font-white);
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4rem 0rem;
}

.section-transitime::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 47%;
  width: 100%;
  height: 630px;
  background-image: url("./images/transitbg-img.jfif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
  transform: translate(-50%, -50%);
  left: 50%;
  opacity: 0.1;
}

.transitime-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  border: 1px solid rgb(0 0 0 / 15%);
  border-radius: 16px;
}

.transitime-wrapper table {
  width: 100%;
  background: #fff;
}

.transitime-wrapper table th,
.transitime-wrapper table td {
  padding: 18px 25px;
  font-size: 18px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  line-height: 22px;
}

.transitime-wrapper table th {
  background-color: #00a6ef;
  color: var(--font-white);
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  line-height: 22px;
  padding: 25px 25px;
}

.transitime-wrapper table tbody tr:nth-child(even) {
  background-color: rgb(240 240 240 / 76%);
  border-radius: 16px;
}

.transitime-wrapper table tbody tr:nth-child(odd) {
  background-color: var(--font-white);
}

.transitime-wrapper table thead tr th:first-of-type {
  border-top-left-radius: 16px;
}

.transitime-wrapper table thead tr th:last-of-type {
  border-top-right-radius: 16px;
}

.transitime-wrapper table tbody tr:last-child td:first-of-type {
  border-bottom-left-radius: 16px;
}

.transitime-wrapper table tbody tr:last-child td:last-of-type {
  border-bottom-right-radius: 16px;
}

.transitime-note {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.transitime-note p {
  text-align: center;
  font-size: 18px;
  color: #f21f5f;
  font-style: italic;
  line-height: 26px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.nav-link {
  font-size: 16px;
  color: #21414d;
  font-weight: 400;
}

.navbar-brand {
  margin-right: 2rem;
}

.navbar-brand img {
  width: 130px;
}

.footer-logo img {
  width: 230px;
}

.section-instantquote {
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 5rem 0rem;
}

.instantquote-form-container {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 2rem 0rem;
  border: 1px solid #eaeaea;
}

.section-italic-description {
  font-size: 20px;
  line-height: 36px;
  text-align: center;
}

.section-italic-description span {
  font-weight: 400;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-style: italic;
  margin-left: 5px;
}

.instantquote-tabs-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 0rem 0rem;
}

.quoteNav {
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 20px;
}

.quoteNav-icons {
  width: 72px;
  height: 72px;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;
}

.quoteNav.nav-pills .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 160px;
}

.quoteNav.nav-pills .nav-link p {
  text-align: center;
  margin-top: 10px;
  color: #49556f;
  font-size: 14px;
  line-height: 22px;
}

.quoteNav.nav-pills .nav-item {
  position: relative;
}

.quoteNav.nav-pills .nav-item::after {
  content: "";
  position: absolute;
  width: 38%;
  height: 4px;
  background-color: #01a7f3;
  right: -20%;
  top: 36%;
  display: inline-block;
}

.quoteNav.nav-pills .nav-item:last-child:after {
  display: none;
}

.quoteNav.nav-pills .nav-link.active {
  background-color: transparent;
}

.quoteNav.nav-pills .nav-link.active .quoteNav-icons {
  background-color: #01a7f3;
}

.tabsubmit-btn-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.tabsubmit-btn-left {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabsubmit-btn-right {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.tabsubmit-btn-right .theme-btn {
  border-radius: 8px;
  min-width: 180px;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
}

.btn-back {
  background-color: #f3f3f3;
  color: #8f95a1;
  transition: ease-in-out 0.3s;
}

.btn-next {
  background-color: #01a7f3;
  color: #fff;
  transition: ease-in-out 0.3s;
}

.btn-next:hover {
  background-color: #0b74a3;
  transition: ease-in-out 0.3s;
}

.addNewContent-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #8f95a1;
  display: flex;
  align-items: center;
  text-align: left;
}

.addNewContent-btn i {
  margin-right: 10px;
  font-size: 32px;
  color: #01a7f3;
}

.tab-content .form-label {
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.quoteNav-icons .inactive {
  display: block;
}

.quoteNav-icons .active {
  display: none;
}

.quoteNav.nav-pills .nav-link.active .quoteNav-icons .inactive {
  display: none;
}

.quoteNav.nav-pills .nav-link.active .quoteNav-icons .active {
  display: block;
}

.formik_error_comp {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: normal;
  margin-top: 0.25rem;
  text-align: left;
  color: #ef2a16;
}

.page-banner-container {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 350px;
  background-image: url("./images/page-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-banner-container::before {
  background-color: rgb(0 0 0 / 70%);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.page-banner-wrapper {
  position: relative;
  z-index: 11;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pagebanner-heading {
  text-align: center;
  font-size: 20px;
  color: var(--font-white);
  margin-bottom: 15px;
}

.pagebanner-content-heading {
  text-align: center;
  font-size: 32px;
  color: var(--font-white);
  margin-bottom: 20px;
}

.pagebanner-description {
  text-align: center;
  color: var(--font-white);
}

.section {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 60px 0px;
}

.banner-bottom-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.banner-bottom-wrapper p {
  font-size: 16px;
  text-align: center;
}

.icon-card-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 20px;
  border-radius: 16px;
  background-color: var(--font-white);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.icon-heading {
  padding: 20px 0px 15px 0px;
  font-size: 18px;
  line-height: 22px;
  color: #19333d;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.how-works {
  background-color: var(--theme-color);
  padding: 0px;
  overflow: hidden;
  margin-bottom: -10px;
}

.contentImage-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.leftfull-content-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 3rem 0rem;
  height: 100%;
}

.rightfull-image-wrapper {
  width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
}

.rightfull-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leftfull-lists {
  width: 100%;
  position: relative;
  display: inline-block;
}

.leftfull-lists-item {
  width: 100%;
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: 25px;
  background-color: rgb(0 0 0 / 20%);
  padding: 15px;
  border-radius: 12px;
}

.leftfull-lists-item:last-child {
  margin-bottom: 0px;
}

.leftfull-count {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff9a27;
  color: var(--font-white);
  font-size: 20px;
  margin-right: 15px;
}

.leftfull-item-content h3 {
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--font-white);
  font-weight: 700;
}

.leftfull-item-content p {
  color: var(--font-white);
}

.bg-card-wrapper {
  background-color: #f4f5f9;
  padding: 30px 25px;
  position: relative;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 16px;
  width: 100%;
}

.bg-card-wrapper img {
  margin: auto;
  text-align: center;
  display: flex;
}

.bg-card-wrapper h3 {
  padding: 20px 0px 15px 0px;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #19333d;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.bg-card-wrapper p {
  text-align: center;
  color: #546e7a;
  font-size: 16px;
  line-height: 24px;
}

.point-card-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.points-item {
  padding: 15px 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme);
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  border: 1px solid var(--theme);
}

.points-item p {
  font-weight: 600;
  color: var(--font-white);
}

.points-item:hover {
  background-color: var(--font-white);
  border: 1px solid var(--heading-color);
}

.points-item:hover p {
  color: var(--heading-color);
}

.bottom-info {
  background-color: rgba(0, 0, 0, 0.1);
}

.bottom-info-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
}

.bottom-info-wrapper p {
  text-align: center;
}

.bottom-info-wrapper .theme-btn {
  margin: auto;
  margin-top: 2rem;
}

.halfbg-wrapper {
  background-color: #f4f5f9;
  padding: 30px 25px;
  position: relative;
  display: flex;
  align-items: flex-start;
  height: 100%;
  border-radius: 16px;
  width: 100%;
}

.halfbg-icon {
  width: 70px;
  height: 70px;
  min-width: 70px;
  background-color: var(--font-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.halfbg-content {
  margin-left: 15px;
}

.halfbg-content h3 {
  padding: 0px 0px 15px 0px;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  color: #19333d;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.halfbg-content p {
  text-align: left;
  color: #546e7a;
  font-size: 16px;
  line-height: 24px;
}

.halfbg-content ul {
  list-style: disc;
  padding-left: 18px;
  margin-top: 15px;
}

.halfbg-content ul li {
  margin-bottom: 10px;
}

.halfbg-content ul li:last-child {
  margin-bottom: 0px;
}

.error_col {
  margin: 3rem;
}
.error_col h3 {
  font-size: 20px;
  margin-bottom: 1rem;
  margin-left: .25rem;
}
.error_cntr {
  width: 100%;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 30px 20px;
}

.error_cntr .error-msg{
  font-size: .9rem;
  font-weight: 600;
}

/* Home v1 styles end */

@media all and (min-width: 1670px) {
  .main-form-container {
    margin-top: -2rem;
  }
}

@media (max-width: 1670px) {
  .main-form-container {
    margin-top: -2rem;
  }
}

@media (max-width: 1440px) {
  .main-form-container {
    margin-top: -5.5rem;
  }
}

@media (max-width: 1380px) {
  .form-wrapper {
    padding: 1.5rem;
  }

  .main-form-container {
    margin-top: -5.5rem;
  }
}

@media (max-width: 1200px) {
  .form-wrapper {
    padding: 1.5rem;
  }
}

@media all and (min-width: 992px) {
}

@media all and (max-width: 992px) {
  .form-wrapper {
    padding: 1.5rem;
  }

  .form-info-banner {
    padding: 0rem 1rem;
  }

  .quote-main-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .quote-item-content div {
    margin-bottom: 10px;
  }

  .quote-item-logo {
    margin-bottom: 0px;
    margin-bottom: 10px;
  }

  .quote-drop-wrapper {
    justify-content: flex-start;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  /* new home stylea start */
  .headerbtns .btn-main {
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  .section-heading {
    font-size: 28px;
    line-height: 40px;
  }

  .banner-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .banner-small-heading {
    font-size: 18px;
    line-height: 30px;
  }

  .main-banner-container {
    height: 550px;
  }

  .quoteNav-icons {
    width: 55px;
    height: 55px;
    border-radius: 16px;
    margin-right: 15px;
  }

  .transitime-wrapper table th,
  .transitime-wrapper table td {
    padding: 12px;
    font-size: 16px;
  }

  .instantquote-form-container {
    padding: 2rem 1rem;
  }

  .tabsubmit-btn-left {
    width: 100%;
    margin-bottom: 15px;
  }

  .headerbtns {
    flex-direction: column;
  }

  .navbar-nav .nav-link {
    text-align: center;
  }

  .main-banner-wrapper .btn-blue {
    min-height: 51px;
    font-size: 18px;
  }

  .footer-bottom-wrapper p {
    text-align: center;
  }

  /* new home stylea end */
}

@media all and (max-width: 768px) {
  .form-wrapper {
    padding: 1rem;
  }

  .form-info-banner {
    padding: 0rem 1rem;
  }

  .quote-main-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .quote-item-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .quote-item-content div {
    margin-bottom: 10px;
  }

  .quote-item-logo {
    margin-bottom: 0px;
    margin-bottom: 10px;
  }

  .quote-drop-wrapper {
    justify-content: flex-start;
  }

  .qcc-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .qcc-head-right {
    text-align: left;
  }

  .qcc-body {
    flex-direction: column;
  }

  .qcc-body-list::after {
    left: 0;
  }

  .qcc-body-list:after {
    display: none;
  }

  .qcc-body-list {
    margin-top: 20px;
  }

  .qcc-body-list:last-child::before {
    background-color: var(--border-color);
  }

  .topheader-wrapper {
    flex-direction: column;
  }

  .get-btns-wrapper {
    flex-direction: column;
  }

  .start-again-btn {
    margin-top: 15px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .banner-header {
    background-image: none;
    background-color: #121b26;
    height: 200px;
  }

  /* new home stylea start */
  .tabsubmit-btn-wrapper {
    flex-direction: column;
  }

  .tabsubmit-btn-right {
    flex-direction: column;
    width: 100%;
  }

  .tabsubmit-btn-right .theme-btn {
    width: 100%;
    max-width: 100%;
  }

  .section-heading {
    font-size: 28px;
    line-height: 40px;
  }

  .banner-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .banner-small-heading {
    font-size: 18px;
    line-height: 30px;
  }

  .main-banner-container {
    height: 550px;
  }

  .quoteNav.nav-pills .nav-item::after {
    display: none;
  }

  .quoteNav {
    flex-direction: column;
  }

  .quoteNav.nav-pills .nav-link {
    flex-direction: initial;
    justify-content: flex-start;
  }

  .quoteNav-icons {
    width: 55px;
    height: 55px;
    border-radius: 16px;
    margin-right: 15px;
  }

  .transitime-wrapper table th,
  .transitime-wrapper table td {
    padding: 12px;
    font-size: 16px;
  }

  .instantquote-form-container {
    padding: 2rem 1rem;
  }

  .tabsubmit-btn-left {
    width: 100%;
    margin-bottom: 15px;
  }

  .headerbtns {
    flex-direction: column;
  }

  .headerbtns .btn-main {
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  .navbar-nav .nav-link {
    text-align: center;
  }

  .main-banner-wrapper .btn-blue {
    width: 100%;
    max-width: 100%;
    min-height: 51px;
    font-size: 18px;
  }

  .footer-bottom-wrapper p {
    text-align: center;
  }

  /* new home stylea end */

  .rightfull-image-wrapper {
    display: none;
  }
}

@media all and (max-width: 480px) {
  .form-wrapper {
    padding: 1rem;
  }

  .form-info-banner {
    padding: 0rem 1rem;
  }

  .quote-main-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .quote-item-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .quote-item-content div {
    margin-bottom: 10px;
  }

  .quote-item-logo {
    margin-bottom: 0px;
    margin-bottom: 10px;
  }

  .quote-drop-wrapper {
    justify-content: flex-start;
  }

  .qcc-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .qcc-head-right {
    text-align: left;
  }

  .qcc-body {
    flex-direction: column;
  }

  .qcc-body-list::after {
    left: 0;
  }

  .qcc-body-list:after {
    display: none;
  }

  .qcc-body-list {
    margin-top: 20px;
  }

  .qcc-body-list:last-child::before {
    background-color: var(--border-color);
  }

  .topheader-wrapper {
    flex-direction: column;
  }

  .get-btns-wrapper {
    flex-direction: column;
  }

  .start-again-btn {
    margin-top: 15px;
  }

  .banner-header {
    background-image: none;
    background-color: #121b26;
    height: 200px;
  }

  /* new home stylea start */
  .tabsubmit-btn-wrapper {
    flex-direction: column;
  }

  .tabsubmit-btn-right {
    flex-direction: column;
    width: 100%;
  }

  .tabsubmit-btn-right .theme-btn {
    width: 100%;
    max-width: 100%;
  }

  .section-heading {
    font-size: 28px;
    line-height: 40px;
  }

  .banner-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .banner-small-heading {
    font-size: 18px;
    line-height: 30px;
  }

  .main-banner-container {
    height: 550px;
  }

  .quoteNav.nav-pills .nav-item::after {
    display: none;
  }

  .quoteNav {
    flex-direction: column;
  }

  .quoteNav.nav-pills .nav-link {
    flex-direction: initial;
    justify-content: flex-start;
  }

  .quoteNav-icons {
    width: 55px;
    height: 55px;
    border-radius: 16px;
    margin-right: 15px;
  }

  .transitime-wrapper table th,
  .transitime-wrapper table td {
    padding: 12px;
    font-size: 16px;
  }

  .instantquote-form-container {
    padding: 2rem 1rem;
  }

  .tabsubmit-btn-left {
    width: 100%;
    margin-bottom: 15px;
  }

  .headerbtns {
    flex-direction: column;
  }

  .headerbtns .btn-main {
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
  }

  .navbar-nav .nav-link {
    text-align: center;
  }

  .main-banner-wrapper .btn-blue {
    width: 100%;
    max-width: 100%;
    min-height: 51px;
    font-size: 18px;
  }

  .footer-bottom-wrapper p {
    text-align: center;
  }

  /* new home stylea end */

  .rightfull-image-wrapper {
    display: none;
  }
}
